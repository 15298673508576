import { determineStoreValueId } from "@/services/ceSettings/ceSettingsService";
import {
  ContentElementImageWithMarkersStoreSetting,
  StoreSetting,
} from "@/types/ceSettings/ceSettings";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";

export default function useImageWithMarkersSettings(
  ceSettings: ContentElementImageWithMarkersStoreSetting | undefined
) {
  const overlayItemBackgroundColor = (
    overlayItemBackgroundColorSetting?: StoreSetting
  ) => {
    const overlayItemBgColorId = determineStoreValueId(
      overlayItemBackgroundColorSetting,
      ceSettings?.textBackgroundColors
    );

    return overlayItemBgColorId
      ? `var(--${STORE_COLOR_CLASS_PREFIX}${overlayItemBgColorId})`
      : undefined;
  };

  const overlayItemMarkerColor = (
    overlayItemMarkerColorSetting: StoreSetting
  ) => {
    const overlayItemMarkerColorId = determineStoreValueId(
      overlayItemMarkerColorSetting,
      ceSettings?.markerColors
    );

    return overlayItemMarkerColorId
      ? `var(--${STORE_COLOR_CLASS_PREFIX}${overlayItemMarkerColorId})`
      : undefined;
  };

  return { overlayItemBackgroundColor, overlayItemMarkerColor };
}
