import {
  dataValueFromCeStoreSetting,
  determineStoreValueId,
} from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";

export default function useStrIcon() {
  const getIcon = (
    iconStoreSetting?: StoreSetting,
    ceSettings?: StoreSetting
  ) => {
    if (iconStoreSetting && ceSettings) {
      const id = determineStoreValueId(iconStoreSetting, ceSettings);
      const storeIcon = dataValueFromCeStoreSetting(ceSettings, id, {});
      const icon = storeIcon?.icon;

      return { icon: icon, id: id };
    }
    return { icon: undefined, id: undefined };
  };

  return { getIcon };
}
